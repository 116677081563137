import { AccountInfo } from "@azure/msal-browser";
import { IMsalContext } from "@azure/msal-react";
import { isDevelopment } from "../helpers";
import { loginRequest } from "./auth";

export const getAccessToken = async (msalInstance: IMsalContext) => {
  if (!isDevelopment) {
    const instance = msalInstance.instance;
    let accounts: AccountInfo[] = [];

    try {
      await instance.initialize();
      const res = await instance.handleRedirectPromise();

      if (!res) {
        accounts = instance.getAllAccounts();
      }

      const currentAccount = (res && res.account) || accounts[0];

      const accessTokenRequest = {
        ...loginRequest,
        account: currentAccount,
      };

      const token = await instance.acquireTokenSilent(accessTokenRequest);
      return token.accessToken;
    } catch (e) {
      console.error(e);
      // return instance.loginRedirect(loginRequest);
    }
  }
};
