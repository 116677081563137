import { loadHints } from "./actions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Hints } from "./types";

const hintsSlice = createSlice({
  name: "Hints",
  initialState: {
    isLoaded: false,
    data: {
      action: {
        values: [],
      },
      resource: {
        values: [],
      },
      condition: {
        elements: {},
      },
    },
  },
  reducers: {},
  extraReducers: {
    [loadHints.fulfilled.toString()]: (
      state: {
        isLoaded: boolean;
        data: Hints;
      },
      action: PayloadAction<{
        hints: Hints;
      }>,
    ): void => {
      state.data = action.payload.hints;
      state.isLoaded = true;
    },
  },
});

export default hintsSlice.reducer;
