import { useEffect, useContext, useRef, useState } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { History } from "history";
import { Modal } from "antd";
import isEqual from "lodash.isequal";

import { IStatement } from "../../pages/AddPolicy/types";
import { IPolicyEntity } from "../../store/Policies/types";

type ExtendNavigator = Navigator & Pick<History, "block">;

type IProps = {
  oldStatements: IStatement[];
  changedStatements: IStatement[];
  oldPolicyId?: string;
  changedPolicyId: string;
  isSaveButtonClicked: boolean;
  json?: IPolicyEntity;
  oldJson?: IPolicyEntity;
};

const DiscardModal = (props: IProps) => {
  const {
    oldStatements,
    changedStatements,
    oldPolicyId,
    changedPolicyId,
    isSaveButtonClicked,
    json,
    oldJson,
  } = props;
  const { navigator } = useContext(NavigationContext);

  const [isModalOpen, setModalOpen] = useState(false);
  const [history, setHistory] = useState("");
  const navigate = useRef(() => {});
  const [isBlock, setBlock] = useState(true);

  useEffect(() => {
    if (!isBlock) {
      navigator.push(history);
    } else {
      const oldJsonWithoutIds = oldJson?.Statement.map(({ Id, ...rest }) => {
        return rest;
      });

      const JsonWithoutIds = json?.Statement.map(({ Id, ...rest }) => {
        return rest;
      });

      if (
        (!isEqual(oldStatements, changedStatements) ||
          (!isEqual(oldJsonWithoutIds, JsonWithoutIds) &&
            oldJson !== undefined) ||
          oldPolicyId !== changedPolicyId) &&
        !isSaveButtonClicked
      ) {
        navigate.current = (navigator as unknown as ExtendNavigator).block(
          tx => {
            setHistory(tx.location.pathname);
            setModalOpen(true);
          },
        );
      }
    }

    return () => {
      navigate.current();
    };
  }, [
    isBlock,
    history,
    oldStatements,
    changedStatements,
    oldJson,
    json,
    oldPolicyId,
    changedPolicyId,
    navigator,
    isSaveButtonClicked,
  ]);

  useEffect(() => {
    if (isModalOpen) {
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "Are you sure you want to leave? Unsaved changes will be lost.",
        maskClosable: true,
        cancelText: "Keep editing",
        okText: "Close without saving",
        okType: "primary",
        onOk: () => {
          setModalOpen(false);
          setBlock(false);
        },
        onCancel: () => {
          setModalOpen(false);
        },
        cancelButtonProps: {
          type: "default",
        },
      });
    }
  }, [isModalOpen]);

  return null;
};

export default DiscardModal;
