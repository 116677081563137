import { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Row,
  Col,
  Radio,
  Empty,
  Select,
  Form,
  RadioChangeEvent,
  Collapse,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import ConditionForm from "../ConditionForm";
import Tooltip from "../../common/Tooltip";
import Button from "../../common/Button";
import { IStatement } from "../../pages/AddPolicy/types";
import { getActionHints, getResourceHints } from "../../store/Hints/selectors";

import "./style.scss";

type IProps = {
  statement: IStatement;
  index: number;
  changeStatement: (
    id: string,
    name: string,
    value: string | Array<string>,
  ) => void;
  changeCondition: (
    statementId: string,
    conditionId: string,
    name: string,
    value?: string | Array<string>,
  ) => void;
  addCondition: (id: string) => void;
  duplicateCondition: (statementId: string, conditionId: string) => void;
  deleteCondition: (statementId: string, conditionId: string) => void;
  changeCheckBox: (
    statementId: string,
    conditionId: string,
    name: string,
    checked: boolean,
  ) => void;
  deleteStatement: (id: string) => void;
};

const StatementForm = ({
  statement,
  changeStatement,
  changeCondition,
  addCondition,
  duplicateCondition,
  index,
  deleteCondition,
  changeCheckBox,
  deleteStatement,
}: IProps) => {
  const { Condition, Id, Resource, Action, Effect } = statement;

  const actions = useSelector(getActionHints);
  const resources = useSelector(getResourceHints);

  const effectChangeHandler = useCallback(
    (event: RadioChangeEvent) => {
      changeStatement(Id, "Effect", event.target.value as string);
    },
    [Id, changeStatement],
  );

  const handleAddCondition = useCallback(
    () => addCondition(Id),
    [Id, addCondition],
  );

  const handleDeleteStatement = useCallback(
    () => deleteStatement(Id),
    [Id, deleteStatement],
  );

  const actionOptions = useMemo(
    () =>
      actions.map(action => (
        <Select.Option key={action.name}>
          <div>{action.name}</div>
          <div className="sf-descr">{action.description}</div>
        </Select.Option>
      )),
    [actions],
  );

  const resourceOptions = useMemo(
    () =>
      resources.map(resource => (
        <Select.Option key={resource.name}>
          <div>{resource.name}</div>
          <div className="sf-descr">{resource.description}</div>
        </Select.Option>
      )),
    [resources],
  );

  const conditions = useMemo(
    () =>
      Condition.length ? (
        Condition.map((item, subIndex) => (
          <ConditionForm
            condition={item}
            key={item.Id}
            changeCondition={changeCondition}
            index={subIndex}
            statementId={Id}
            duplicateCondition={duplicateCondition}
            deleteCondition={deleteCondition}
            changeCheckBox={changeCheckBox}
          />
        ))
      ) : (
        <Empty
          description="Add a condition to limit the statement"
          className="empty-condition-form"
        />
      ),
    [
      Condition,
      Id,
      changeCondition,
      deleteCondition,
      changeCheckBox,
      statement,
    ],
  );

  const panels = useMemo(() => {
    const keys: Array<string> = [];
    keys.push(`${index + 1}`);

    return keys.map(key => key);
  }, []);

  return (
    <Collapse defaultActiveKey={panels}>
      <Collapse.Panel
        className="statement-collapse"
        header={`Statement #${index + 1}`}
        key={`${index + 1}`}
        extra={
          <Button
            type="default"
            icon={<DeleteOutlined />}
            onClick={handleDeleteStatement}
          >
            Delete statement
          </Button>
        }
      >
        <Card
          className="statement-form"
          bodyStyle={{
            padding: "0",
            background: "#FFF",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Row>
            <Col className="left-column">
              <Form.Item
                colon={false}
                name={`Access_${Id}`}
                rules={[
                  {
                    required: true,
                    message: "The field is required.",
                  },
                ]}
                label={
                  <Tooltip
                    title="Access type"
                    tooltip="This section describes the access parameters.
                            Access type determines whether you deny or allow the action to the resource below."
                  />
                }
                initialValue={Effect}
              >
                <Radio.Group name="Effect" onChange={effectChangeHandler}>
                  <Radio.Button value="Allow">Allow</Radio.Button>
                  <Radio.Button value="Deny">Deny</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                colon={false}
                name={`Action_${Id}`}
                rules={[
                  {
                    required: true,
                    message: "The field is required.",
                  },
                  {
                    pattern: /^([a-z]{1,32}:[A-Za-z*]{1,64}|\*)$/,
                    message: "Format is not right.",
                  },
                ]}
                label={
                  <Tooltip
                    title="Action"
                    tooltip="If the action is to access a particular service, enter the following text:
                  authz:GetKeys2.
                  For other cases, contact Administrator."
                  />
                }
                initialValue={Action}
              >
                <Select
                  mode="tags"
                  allowClear
                  className="select-field"
                  value={Action}
                  onChange={(value: Array<string>) => {
                    changeStatement(Id, "Action", value);
                  }}
                >
                  {actionOptions}
                </Select>
              </Form.Item>
              <Form.Item
                colon={false}
                name={`resource_${Id}`}
                rules={[
                  {
                    required: true,
                    message: "The field is required.",
                  },
                  {
                    pattern: /^([a-z]{1,32}:[A-Za-z0-9:/*._-]{1,256}|\*)$/,
                    message: "Format is not right.",
                  },
                ]}
                initialValue={Resource}
                label={
                  <Tooltip
                    title="Resource"
                    tooltip="Enter the field value in the following format:
                    arn:[system_name]:iam:*.[role/]*:
                    
                    system_name — which site/application will be configured to access (no spaces or special symbols)
                    role/ — who get access: either just [role/] if any person will get access, or [role/role_type] if a specific role will get access."
                  />
                }
              >
                <Select
                  mode="tags"
                  allowClear
                  className="select-field"
                  value={Resource}
                  onChange={(value: Array<string>) => {
                    changeStatement(Id, "Resource", value);
                  }}
                >
                  {resourceOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col className="right-column">
              {conditions}
              <Button
                className="add-condition-button"
                type="dashed"
                icon={<PlusOutlined />}
                size="large"
                onClick={handleAddCondition}
              >
                Add condition
              </Button>
            </Col>
          </Row>
        </Card>
      </Collapse.Panel>
    </Collapse>
  );
};

export default memo(StatementForm);
