import { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import { Row, Col, Select, Form } from "antd";

import { getSubjectAttributeHints } from "../../../../store/Hints/selectors";
import Tooltip from "../../../../common/Tooltip";

import { ApplyToOptions } from "../../../../const";

import { ICondition } from "../../../../pages/AddPolicy/types";

import "./style.scss";

type IProps = {
  condition: ICondition;
  changeCondition: (
    statementId: string,
    conditionId: string,
    name: string,
    value?: Array<string> | string,
  ) => void;
  statementId: string;
};

const ForValues = ({ condition, changeCondition, statementId }: IProps) => {
  const attributeHints = useSelector(getSubjectAttributeHints);

  const attributesValues = useMemo(() => {
    return condition.Subject &&
      attributeHints[condition.Subject] &&
      condition.Attribute &&
      attributeHints[condition.Subject].keys[condition.Attribute]
      ? attributeHints[condition.Subject].keys[condition.Attribute].values
      : [];
  }, [condition.Subject, condition.Attribute, attributeHints]);

  const handleChangeOption = (name: string, value?: string | Array<string>) => {
    changeCondition(statementId, condition.Id, name, value);
  };

  return (
    <Row gutter={16}>
      <Col>
        <Form.Item
          colon={false}
          name={`Value_${statementId}_${condition.Id}`}
          rules={[
            {
              required: true,
              message: "The field is required.",
            },
          ]}
          label={
            <Tooltip
              title="Attribute's value"
              tooltip="Attribute's value is the value the condition should match. It can contain exact names, numbers, parts of numbers, or individual characters.
                E.g., datascale-access, admin, 1234"
            />
          }
          initialValue={condition.Value}
        >
          <Select
            mode="tags"
            allowClear
            value={condition.Value}
            className="condition-attributes"
            onChange={(value: Array<string>) =>
              handleChangeOption("Value", value)
            }
            disabled={!condition.Format}
          >
            {attributesValues.map(item => (
              <Select.Option key={item.name}>
                <div>{item.name}</div>
                <div className="sf-descr">{item.description}</div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col>
        <Tooltip
          tooltip="This field means how flexible the condition is - is it mandatory for all entered values or can it be applied to at least one value?"
          title="Qualifier"
        />

        <Select
          value={condition.ApplyTo}
          className="access-type-field"
          onChange={(value: string) => handleChangeOption("ApplyTo", value)}
        >
          {ApplyToOptions.map(item => (
            <Select.Option value={item.value} key={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default memo(ForValues);
