import React from "react";
import { Tag } from "antd";
import { OperatorOptions } from "../../const/index";

interface Props {
  value: string | undefined;
}

const Operator: React.FC<Props> = ({ value, ...props }) => {
  const generatedOption = React.useMemo(() => {
    return OperatorOptions("numeric").find(item => item.value === value);
  }, [value]);

  return (
    <Tag color={generatedOption?.color} {...props}>
      {generatedOption?.name}
    </Tag>
  );
};

export default React.memo(Operator);
