import { memo, useCallback, useMemo } from "react";

import { Button, Card, Typography, Row, Col, Divider, Collapse } from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";

import Subject from "./components/Subject";
import MeetsCondition from "./components/MeetsCondition";
import ForValues from "./components/ForValues";

import { ICondition } from "../../pages/AddPolicy/types";

import "./style.scss";

type IProps = {
  condition: ICondition;
  changeCondition: (
    statementId: string,
    conditionId: string,
    name: string,
    value?: Array<string> | string,
  ) => void;
  index: number;
  statementId: string;
  duplicateCondition: (statementId: string, conditionId: string) => void;
  deleteCondition: (statementId: string, conditionId: string) => void;
  changeCheckBox: (
    statementId: string,
    conditionId: string,
    name: string,
    checked: boolean,
  ) => void;
};

const ConditionForm = ({
  condition,
  changeCondition,
  index,
  statementId,
  duplicateCondition,
  deleteCondition,
  changeCheckBox,
}: IProps) => {
  const handleDeleteCondition = useCallback(
    () => deleteCondition(statementId, condition.Id),
    [statementId, condition.Id, deleteCondition],
  );

  const handleDuplicateCondition = useCallback(
    () => duplicateCondition(statementId, condition.Id),
    [statementId, condition.Id, duplicateCondition],
  );

  const panels = useMemo(() => {
    const keys: Array<string> = [];
    keys.push(`${index + 1}`);

    return keys.map(key => key);
  }, []);

  return (
    <Collapse defaultActiveKey={panels}>
      <Collapse.Panel
        header={`Condition #${index + 1}`}
        className="condition-collapse"
        key={`${index + 1}`}
        extra={
          <div>
            <Button
              icon={<DeleteOutlined />}
              type="link"
              onClick={handleDeleteCondition}
              className="condition-button"
            >
              Delete
            </Button>
            <Button
              icon={<CopyOutlined />}
              type="link"
              onClick={handleDuplicateCondition}
            >
              Duplicate
            </Button>
          </div>
        }
      >
        <Card
          className="condition"
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            padding: "32px 24px 24px 24px",
          }}
        >
          <Row>
            <Col className="left-col">
              <Typography.Text className="title">Subject:</Typography.Text>
            </Col>
            <Col>
              <Divider type="vertical" dashed className="divider" />
            </Col>
            <Col>
              <Subject
                changeCondition={changeCondition}
                statementId={statementId}
                condition={condition}
              />
            </Col>
          </Row>
          <Row>
            <Col className="left-col">
              <Typography.Text className="title">
                Meets condition:
              </Typography.Text>
            </Col>
            <Col>
              <Divider type="vertical" dashed className="divider" />
            </Col>
            <Col>
              <MeetsCondition
                changeCheckBox={changeCheckBox}
                changeCondition={changeCondition}
                statementId={statementId}
                condition={condition}
              />
            </Col>
          </Row>
          <Row>
            <Col className="left-col">
              <Typography.Text className="title">For values</Typography.Text>
            </Col>
            <Col>
              <Divider type="vertical" dashed className="divider" />
            </Col>
            <Col>
              <ForValues
                changeCondition={changeCondition}
                statementId={statementId}
                condition={condition}
              />
            </Col>
          </Row>
        </Card>
      </Collapse.Panel>
    </Collapse>
  );
};

export default memo(ConditionForm);
