import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Typography, Tooltip as AntToolTip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";

import "./style.scss";

type IProps = {
  placement?: TooltipPlacement;
  title?: string;
  tooltip: React.ReactNode | string;
  boxClassName?: string;
  className?: string;
};

const Tooltip = ({
  placement,
  title,
  tooltip,
  boxClassName,
  className = "",
}: IProps) => (
  <div className={boxClassName}>
    {title && <Typography.Text>{title}</Typography.Text>}
    <AntToolTip
      className={`tooltip ${className}`}
      title={tooltip}
      placement={placement}
    >
      <InfoCircleOutlined />
    </AntToolTip>
  </div>
);

export default Tooltip;
