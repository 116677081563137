import React, { ChangeEvent, Dispatch, useCallback } from "react";
import { Form, Input, Segmented, Select, Typography } from "antd";
import {
  PlusOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { SegmentedValue } from "antd/lib/segmented";
import { Link } from "react-router-dom";

import Button from "../../common/Button";

import "./style.scss";

const { Search } = Input;
const { Title } = Typography;

interface IProps {
  setSearchItems: Dispatch<string>;
  setEffect: (value: string) => void;
  effect: string;
  setSort: (value: string) => void;
  sort: string;
  setSegment: (value: SegmentedValue) => void;
  segment: SegmentedValue;
}

const ListPageHeader: React.FC<IProps> = ({
  setSearchItems,
  effect,
  setEffect,
  sort,
  setSort,
  setSegment,
  segment,
}) => {
  const changeViewFormat = useCallback((value: SegmentedValue) => {
    setSegment(value);
  }, []);

  const handleSearchItems = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchItems(e.target.value);
    },
    [setSearchItems],
  );

  return (
    <div className="list-header">
      <Title level={2} className="lh-title">
        Policies list
      </Title>
      <div className="lh-wrapper">
        <Search
          placeholder="Search policy by name, action ..."
          className="lh-search"
          onChange={handleSearchItems}
        />
        <Form layout="inline" className="header-form">
          <Form.Item name="Effect" className="header-form-item">
            <Select
              defaultValue="All"
              className="effect-select"
              value={effect}
              onChange={setEffect}
            >
              <Select.Option value="All">Show all actions</Select.Option>
              <Select.Option value="Deny">Show only Deny</Select.Option>
              <Select.Option value="Allow">Show only Allow</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="Sort" className="header-form-item">
            <Select
              defaultValue="A-Z"
              className="sort-select"
              value={sort}
              onChange={setSort}
            >
              <Select.Option value="A-Z">Sort from A to Z</Select.Option>
              <Select.Option value="Z-A">Sort from Z to A</Select.Option>
            </Select>
          </Form.Item>
        </Form>

        <Link to="/addPolicy">
          <Button size="large" icon={<PlusOutlined />} type="primary">
            Add policy
          </Button>
        </Link>
        <Segmented
          value={segment}
          onChange={changeViewFormat}
          size="large"
          options={[
            {
              value: "List",
              icon: <TableOutlined />,
            },
            {
              value: "Table",
              icon: <UnorderedListOutlined />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default React.memo(ListPageHeader);
