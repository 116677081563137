import React from "react";
import { Typography } from "antd";

import "./style.scss";

const { Title } = Typography;

const Main = () => {
  return (
    <div className="main">
      <Title level={1} className="main-title">
        Self-service for creating policies
      </Title>
      <div className="main-subtitle">View and create your policies</div>
      <p className="main-descr">
        See what access permissions you've granted in Bayer system. Create new
        access policies without skills in coding.
      </p>
    </div>
  );
};

export default Main;
