export interface IConditionEntity {
  [index: string]: {
    [index: string]: Array<string>;
  };
}

export interface IStatementEntity {
  Effect?: string;
  Action: Array<string>;
  Resource: Array<string>;
  Condition: IConditionEntity;
  Id: string;
}

export type IPolicyEntity = {
  Id: string;
  Statement: Array<IStatementEntity>;
};

export enum EModes {
  Success = "success",
  Error = "error",
}

export interface INotification {
  mode: EModes;
  title: string;
  subTitle?: string;
  connection?: boolean;
}
