import logger from "redux-logger";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import policiesSlice from "./Policies/slice";
import hintsSlice from "./Hints/slice";
import { isDevelopment } from "../helpers";

const rootReducer = combineReducers({
  policiesSlice,
  hintsSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
  devTools: isDevelopment,
});

export type TypeState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
