import { Routes, Route } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Header from "./components/Header";
import AddPolicy from "./pages/AddPolicy";
import EditPolicy from "./pages/EditPolicy";
import Policies from "./pages/Policies";
import Main from "./pages/Main";
import NotFound from "./pages/404";
import { isDevelopment } from "./helpers";
import { useMemo } from "react";

function App() {
  const routes = useMemo(
    () => (
      <Routes>
        <Route path="/" element={<Policies />}></Route>
        <Route path="/home" element={<Main />}></Route>
        <Route path="/addPolicy" element={<AddPolicy />}></Route>
        <Route path="/editPolicy/:id" element={<EditPolicy />}></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/404" element={<NotFound />}></Route>
      </Routes>
    ),
    [],
  );

  return (
    <div className="app">
      <Header />
      {!isDevelopment ? (
        <>
          <AuthenticatedTemplate>{routes}</AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Main />
          </UnauthenticatedTemplate>
        </>
      ) : (
        routes
      )}
    </div>
  );
}

export default App;
