import { useCallback, useMemo, memo } from "react";
import { Card, Divider, Row, Col, Descriptions, Table, Empty } from "antd";
import { IStatement } from "../../pages/AddPolicy/types";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { policiesColumns } from "../../const";

import Operator from "../../common/Operator";
import ListPopup from "../../common/ListPopup";

import "./style.scss";

const PolicyStatement = ({ statement }: { statement: IStatement }) => {
  const { Effect, Action, Resource, Condition } = statement;

  const getLabel = useCallback(
    (label: string) => (
      <div className="get-label">
        <span>{label}</span>
        <span>:</span>
      </div>
    ),
    [],
  );

  const getConditions = useMemo(
    () =>
      Condition.map((item, index) => ({
        key: index,
        subject: `${item.Subject}:${item.Attribute}`,
        operator: <Operator value={item.Operator} />,
        value: (
          <ul className="list">
            {item.Value.map(
              (subItem: string): JSX.Element => (
                <li key={subItem}>{subItem}</li>
              ),
            )}
          </ul>
        ),
        details: (
          <div>
            <div>{`${item.Format}${item.IgnoreCase ? "IgnoreCase" : ""}${
              item.IfExists ? "IfExists" : ""
            }`}</div>
            <div>{item.ApplyTo}</div>
          </div>
        ),
      })),
    [Condition],
  );

  const labelStyle = useMemo(
    () => ({
      width: 112,
    }),
    [],
  );

  return (
    <Card
      bodyStyle={{
        padding: 0,
      }}
      bordered
      className="policy-statement"
    >
      <Row>
        <Col className="left-column">
          <Descriptions
            column={1}
            size="small"
            className="descriptions"
            colon={false}
          >
            <Descriptions.Item
              label={getLabel("Effect")}
              labelStyle={labelStyle}
            >
              <div className="effectField">
                {Effect}
                {Effect === "Allow" ? (
                  <CheckCircleFilled className="icon-allow" />
                ) : (
                  <CloseCircleFilled className="icon-deny" />
                )}
              </div>
            </Descriptions.Item>
            <Descriptions.Item
              label={getLabel("Actions")}
              labelStyle={labelStyle}
            >
              <ListPopup list={Action} />
            </Descriptions.Item>
            <Descriptions.Item
              label={getLabel("Resource")}
              labelStyle={labelStyle}
            >
              <ListPopup list={Resource} />
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col>
          <Divider type="vertical" className="vertical-divider" />
        </Col>
        <Col className="right-column">
          <Table
            dataSource={getConditions}
            columns={policiesColumns}
            pagination={false}
            rowClassName="row"
            locale={{
              emptyText: (
                <Empty className="empty-text" description="No conditions" />
              ),
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default memo(PolicyStatement);
