import React, { memo, useMemo } from "react";

import { Card, Input, Form, Alert, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import StatementForm from "../../components/StatementForm";
import Tooltip from "../../common/Tooltip";
import Button from "../../common/Button";

import { IPolicy, IStatement } from "../../pages/AddPolicy/types";

type IProps = {
  policy?: IPolicy;
  policyId: string;
  statements: IStatement[];
  duplicateCondition: (statementId: string, conditionId: string) => void;
  changeStatement: (
    id: string,
    name: string,
    value: string | Array<string>,
  ) => void;
  changeCondition: (
    statementId: string,
    conditionId: string,
    name: string,
    value?: string | Array<string>,
  ) => void;
  addCondition: (id: string) => void;
  deleteCondition: (statementId: string, conditionId: string) => void;
  changeCheckBox: (
    statementId: string,
    conditionId: string,
    name: string,
    checked: boolean,
  ) => void;
  deleteStatement: (id: string) => void;
  changePolicyId: (id: string) => void;
  savePolicy: () => void;
  addStatement: () => void;
  hasError?: boolean;
  isEditPage: boolean;
};

const PolicyForm = ({
  policy,
  policyId,
  statements,
  changePolicyId,
  changeCondition,
  changeStatement,
  addCondition,
  deleteCondition,
  changeCheckBox,
  deleteStatement,
  addStatement,
  savePolicy,
  hasError,
  isEditPage,
  duplicateCondition,
}: IProps) => {
  const handleChangePolicyId = (event: React.ChangeEvent<HTMLInputElement>) =>
    changePolicyId(event.target.value);

  const statementData = useMemo(
    () =>
      statements.map((statement, index) => (
        <StatementForm
          index={index}
          statement={statement}
          key={statement.Id}
          changeStatement={changeStatement}
          changeCondition={changeCondition}
          addCondition={addCondition}
          deleteCondition={deleteCondition}
          changeCheckBox={changeCheckBox}
          deleteStatement={deleteStatement}
          duplicateCondition={duplicateCondition}
        />
      )),
    [
      changeCondition,
      changeStatement,
      addCondition,
      deleteCondition,
      changeCheckBox,
      deleteStatement,
      statements,
    ],
  );

  return (
    <Form
      name="basic"
      onFinish={savePolicy}
      autoComplete="off"
      requiredMark={false}
      layout="vertical"
      id="policyForm"
    >
      <div className="add-policy-body">
        {!isEditPage && (
          <Card
            className="add-policy-card"
            title="General info"
            bodyStyle={{
              padding: "24px",
              background: "#FFF",
              borderTop: "1px solid #F0F0F0",
              display: "flex",
              flexDirection: "column",
            }}
            headStyle={{
              fontWeight: 400,
            }}
          >
            <Form.Item
              colon={false}
              name="policy"
              rules={[
                {
                  required: true,
                  message: "The field is required.",
                },
                {
                  pattern: /^([a-zA-Z](w|.|-){0,255})$/,
                  message: "Format is not right.",
                },
              ]}
              label={
                <Tooltip
                  title="Policy ID"
                  tooltip="Policy is a group of permissions that you give to someone on a particular system. Policy ID should contain only letters  (no spaces or special symbols).
              For example: 'Accessprouptomygroup1'."
                />
              }
              initialValue={policyId}
            >
              <Input
                name="policy"
                placeholder="Policy ID"
                className="policy-id-input"
                size="large"
                onChange={handleChangePolicyId}
                value={policyId}
              />
            </Form.Item>
          </Card>
        )}

        <div
          className={`add-policy-statements ${
            policy ? "add-policy-statements-reset" : ""
          }`}
        >
          <Card
            title="Statements"
            className="statements-header"
            bodyStyle={{
              display: "none",
            }}
            bordered={false}
            extra={
              <Button
                type="secondary"
                size="middle"
                icon={<PlusOutlined />}
                onClick={addStatement}
              >
                Add statement
              </Button>
            }
          ></Card>

          {!statements.length && (
            <>
              {hasError && (
                <Alert
                  message="At least one statement should be added"
                  type="error"
                  showIcon
                />
              )}
              <Empty className="no-statements" description="No statements" />
            </>
          )}
          {statementData}
        </div>
      </div>
    </Form>
  );
};

export default memo(PolicyForm);
