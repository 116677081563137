import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  PlusOutlined,
  UnorderedListOutlined,
  LogoutOutlined,
  LoginOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { Dropdown, MenuProps, Space, Menu, Modal } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/auth";
import { mailTo, teamChannel, userGuide } from "../../const";
import Button from "../../common/Button";
import { isDevelopment } from "../../helpers";

import "./style.scss";

const Header: React.FC = () => {
  const [current, setCurrent] = useState("");
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const accountName: string | undefined = accounts[0]?.name;

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      current !== "list" && setCurrent("list");
    } else if (location.pathname === "/addPolicy") {
      current !== "add" && setCurrent("add");
    } else {
      setCurrent("");
    }
  }, [location.pathname, current]);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, [isModalVisible]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, [isModalVisible]);

  const handleLogin = useCallback(() => {
    instance.loginPopup(loginRequest).catch(e => {
      console.log(e);
    });
  }, [instance]);

  const handleLogout = useCallback(() => {
    instance.logoutPopup().catch(e => {
      console.log(e);
    });
  }, [instance]);

  const menuItemsSwitch: MenuProps["onClick"] = event => {
    setCurrent(event.key);
  };

  const logoutItem = useMemo(
    () => (
      <Menu
        items={[
          {
            label: (
              <div onClick={handleLogout} className="header-logout-btn">
                <LogoutOutlined className="logout-icon" />
                Log out
              </div>
            ),
            key: "0",
            className: "header-logout",
          },
        ]}
      />
    ),
    [handleLogout],
  );

  const supportItems = useMemo(
    () => (
      <Menu
        items={[
          {
            label: (
              <a href={userGuide} target="_blank">
                User guide
              </a>
            ),
            className: "header-support-item",
            key: "0",
          },
          {
            label: <div onClick={showModal}>Version</div>,
            className: "header-support-item",
            key: "1",
          },
          {
            label: (
              <a href={teamChannel} target="_blank">
                Contact support
              </a>
            ),
            className: "header-support-item",
            key: "2",
          },
          {
            label: (
              <a href={mailTo} target="_blank">
                Contact Product Owner
              </a>
            ),
            className: "header-support-item",
            key: "3",
          },
        ]}
      />
    ),
    [],
  );

  return (
    <header
      className={`header ${
        isDevelopment || isAuthenticated ? "" : "header-auth"
      }`}
    >
      <div className="header-left">
        <Link to="/home" className="logo">
          Access Layer
        </Link>
        {isDevelopment || isAuthenticated ? (
          <Menu
            onClick={menuItemsSwitch}
            selectedKeys={[current]}
            mode="horizontal"
          >
            <Menu.Item key="list" icon={<UnorderedListOutlined />}>
              <Link to="/">Policies list</Link>
            </Menu.Item>
            <Menu.Item key="add" icon={<PlusOutlined />}>
              <Link to="/addPolicy">Add policy</Link>
            </Menu.Item>
          </Menu>
        ) : null}
      </div>
      {isDevelopment || isAuthenticated ? (
        <div className="header-right">
          <Modal
            title="Version"
            visible={isModalVisible}
            onCancel={handleCancel}
            className="version-modal"
            okButtonProps={{
              className: "okButton",
            }}
            cancelButtonProps={{
              className: "cancelButton",
            }}
          >
            <p>
              You have installed Access Layer application version 1.5.00.17261.
              Last update: 02.07.2022.
            </p>
          </Modal>
          <Dropdown
            overlay={supportItems}
            className="header-support-dropdown"
            trigger={["click"]}
          >
            <div>
              <Space className="header-support-button">
                <QuestionOutlined />
              </Space>
            </div>
          </Dropdown>
          <Dropdown overlay={logoutItem} trigger={["click"]}>
            <div className="header-account">
              <Space>{accountName}</Space>
            </div>
          </Dropdown>
        </div>
      ) : (
        <Button
          icon={<LoginOutlined />}
          onClick={handleLogin}
          type="primary"
          size="large"
        >
          Login
        </Button>
      )}
    </header>
  );
};

export default Header;
