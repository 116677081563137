import { memo, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Row, Col, Select, AutoComplete, Form, Input } from "antd";

import { getSubjectAttributeHints } from "../../../../store/Hints/selectors";
import Tooltip from "../../../../common/Tooltip";
import { FormatOfAttributeOptions, SubjectOptions } from "../../../../const";
import { ICondition } from "../../../../pages/AddPolicy/types";

import "./style.scss";

type IProps = {
  condition: ICondition;
  changeCondition: (
    statementId: string,
    conditionId: string,
    name: string,
    value?: Array<string> | string,
  ) => void;
  statementId: string;
};

const Subject = ({ condition, changeCondition, statementId }: IProps) => {
  const [attributeOptions, setAttributeOptions] = useState<
    {
      value: string;
      descr?: string;
    }[]
  >([]);
  const attributeHints = useSelector(getSubjectAttributeHints);

  const attributes = useMemo(() => {
    const currentHints =
      condition.Subject &&
      attributeHints[condition.Subject] &&
      attributeHints[condition.Subject].keys;
    const options = [];

    if (currentHints) {
      for (const [name, attr] of Object.entries(currentHints)) {
        options.push({
          value: name,
          descr: attr.descr,
        });
      }
    }

    return options;
  }, [condition.Subject, attributeHints]);

  useEffect(() => {
    attributes && setAttributeOptions(attributes);
  }, [attributes]);

  const showAttributeOptions = useMemo(
    () =>
      attributeOptions.map(option => ({
        value: option.value,
        label: (
          <div>
            <div>{option.value}</div>
            <div className="sf-descr">{option.descr}</div>
          </div>
        ),
      })),
    [attributeOptions],
  );

  const onSearch = (searchText: string) => {
    setAttributeOptions(
      attributes.filter(item => item.value.includes(searchText)),
    );
  };

  const handleChangeOption = (name: string, value?: string | Array<string>) => {
    changeCondition(statementId, condition.Id, name, value);
  };

  return (
    <Row gutter={16}>
      <Col>
        <Form.Item
          label={
            <Tooltip
              tooltip="The fields on the left describe the access parameters. Condition determines who will be given that access.
                The subject and its attribute mean which parameter the system will use to distinguish who gets access. If you are not sure, what attribute is needed, type the suggested one.
                E.g. user’s group"
              title="Subject and its attribute"
            />
          }
        >
          <Input.Group compact>
            <Form.Item
              name={`Subject_${statementId}_${condition.Id}`}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Subject is required.",
                },
              ]}
              initialValue={condition.Subject}
            >
              <Select
                className="subject-field"
                value={condition.Subject}
                onChange={(value: string) => {
                  handleChangeOption("Subject", value);
                }}
                placeholder="Select"
              >
                {SubjectOptions.map(item => (
                  <Select.Option value={item.value} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={`Attribute_${statementId}_${condition.Id}`}
              noStyle
              rules={[
                {
                  required: true,
                  message: "Attribute is required.",
                },
              ]}
              initialValue={condition.Attribute}
            >
              <AutoComplete
                options={showAttributeOptions}
                className="attribute-field"
                onChange={(value: string) =>
                  handleChangeOption("Attribute", value)
                }
                onSearch={onSearch}
                value={condition.Attribute}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          colon={false}
          name={`Format_${statementId}_${condition.Id}`}
          rules={[
            {
              required: true,
              message: "The field is required.",
            },
          ]}
          label={
            <Tooltip
              tooltip="Format of attribute determines in what format an attribute parameter is normally provided.
              E.g., user’s group is specified by a text value. If the value can include both text and numbers, select 'text'."
              title="Format of attribute"
            />
          }
          initialValue={condition.Format}
        >
          <Select
            className="condition-format"
            value={condition.Format as string}
            onChange={(value: string) => {
              handleChangeOption("Format", value);
            }}
            placeholder="Select"
          >
            {FormatOfAttributeOptions.map(item => (
              <Select.Option value={item.value} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default memo(Subject);
