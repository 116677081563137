import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store";
import { MsalProvider } from "@azure/msal-react";
import {
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./config/auth";
import "antd/dist/antd.min.css";
import "./scss/style.scss";

const rootElement = document.getElementById("root");

if (!rootElement) throw new Error("Failed to find the root element");

export const msalInstance: IPublicClientApplication =
  new PublicClientApplication(msalConfig);

const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </Provider>,
);
